@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-category {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease;
  &:hover > .btn {
    transition: all 0.3s ease;
  }

  @include fixedSize(100%, 122px); //142px
  border-radius: var(--cornerradius10);
  padding: 12px;
  box-shadow: var(--elevation-1);
  outline: 2px solid var(--cst-cards-category-outline-default);
  outline-offset: -2px;
  overflow: hidden;

  @include min-428-break {
    @include fixedSize(100%, 163px); //193px
    border-radius: var(--cornerradius14);
  }

  @include min-744-break {
    @include fixedSize(100%, 163px); //222px
    border-radius: var(--cornerradius14);
  }

  @include min-1024-break {
    @include fixedSize(100%, 214px); //222px
    border-radius: var(--cornerradius10);
    padding: 20px;
  }

  @include min-1440-break {
    @include fixedSize(100%, 204px); //222px
    border-radius: var(--cornerradius12);
  }

  @include min-1920-break {
    @include fixedSize(100%, 232px); //222px
    border-radius: var(--cornerradius14);
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include min-428-break {
      gap: 6px;
    }

    .top-container {
      display: flex;
      gap: 6px;
      align-items: flex-start;
    }

    .title {
      color: var(--cst-cards-category-title-default);
      @include typography-base;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-l;
        @include weight-semi-bold;
      }

      @include min-1024-break {
        @include typography-m;
        @include weight-semi-bold;
      }

      @include min-1440-break {
        @include typography-l;
        @include weight-semi-bold;
      }

      @include min-1920-break {
        @include typography-xl;
        @include weight-semi-bold;
      }
    }

    .subtitle {
      color: var(--cst-cards-category-subtitle-hover);
      @include typography-s;
      @include weight-medium;
      @include min-428-break {
        @include typography-m;
        @include weight-medium;
      }
    }

    .icon {
      @include singleFixedSize(18px);

      @include min-428-break {
        @include singleFixedSize(24px);
      }
      @include min-1440-break {
        @include singleFixedSize(26px);
      }

      @include min-1920-break {
        @include singleFixedSize(30px);
      }
    }
  }

  .star {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-24px, -20px);
    z-index: -1;

    @include min-428-break {
      transform: translate(-33px, -25px);
    }

    .card-category__item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .item {
        z-index: -1;
        position: absolute;
        pointer-events: none;

        @include singleFixedSize(92px);

        top: -64px;
        left: -68px;

        @include min-428-break {
          @include singleFixedSize(124px);

          top: -90px;
          left: -90px;
        }

        @include min-1024-break {
          top: -85px;
          left: -88px;
        }

        @include min-1920-break {
          @include singleFixedSize(152px);
          top: -115px;
          left: -120px;
        }
      }

      .background {
        position: absolute;
        z-index: -2;
        top: -70px;
        left: -74px;
        @include singleFixedSize(118px);

        @include min-428-break {
          @include singleFixedSize(157px);

          top: -100px;
          left: -94px;
        }

        @include min-1024-break {
          top: -81px;
          left: -88px;
        }

        @include min-1920-break {
          @include singleFixedSize(196px);
          top: -112px;
          left: -125px;
        }
      }
    }
  }
  .btn {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cst-cards-category-backgroundicon-default);
    border-radius: var(--cornerradius6);
    &:active {
      background-color: var(--cst-cards-category-backgroundicon-hover);
    }

    @include singleFixedSize(30px);

    @include min-428-break {
      border-radius: var(--cornerradius8);
      @include singleFixedSize(40px);
    }

    @include min-1024-break {
      @include singleFixedSize(36px);
      border-radius: var(--cornerradius6);
    }

    @include min-1440-break {
      @include singleFixedSize(42px);
      border-radius: var(--cornerradius8);
    }

    @include min-1920-break {
      @include singleFixedSize(52px);
      border-radius: var(--cornerradius12);
    }

    .arrow-icon {
      color: var(--cst-cards-catalog-icon-default);

      @include singleFixedSize(18px);

      @include min-428-break {
        @include singleFixedSize(24px);
      }
      @include min-1440-break {
        @include singleFixedSize(26px);
      }

      @include min-1920-break {
        @include singleFixedSize(32px);
      }
    }
  }
}

.disabled {
  pointer-events: none;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  opacity: 0.7;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
  }
  @include fixedSize(inherit, inherit);
}
